import React from "react"
import kebabCase from "lodash/kebabCase"
import styled from "@emotion/styled"
import { useTagItems } from "../hooks/use-tag-items"
import { Link } from "gatsby"
const NavItemWrapper = styled.ul`
    order: 1;
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex: 1;
`
const NavItem = styled.li`
    margin-right: 2em;
`
export default () => {
    const { group } = useTagItems()
    return (
        <NavItemWrapper>
            {group.map(tag => (
                <NavItem key={tag.fieldValue}>
                    <Link to={`${kebabCase(tag.fieldValue)}/`}>
                        {tag.fieldValue}
                    </Link>
                </NavItem>
            ))}
        </NavItemWrapper>
    )
}