import { useStaticQuery, graphql } from "gatsby"
export const useTagItems = () => {
    const { allMarkdownRemark } = useStaticQuery(
        graphql`
            query TagItems {
                allMarkdownRemark(limit: 2000) {
                    group(field: frontmatter___tags) {
                        fieldValue
                    }
                }
            }
        `
    )
    return allMarkdownRemark
}