import React from "react"
import styled from "@emotion/styled"
import BlogNav from "./blog-nav-items"
import { useStaticQuery, Link, graphql } from "gatsby"
const LayoutWrapper = styled.div`
    padding: 0;
`
const HeaderWrapper = styled.header`
    min-height: 4em;
    padding: 1.1em 4em 0em 4em;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid black;
`
const BrandWrapper = styled.div`
    order: 2;
    text-align: center;
    flex: 1;
`
const SecondaryNavItems = styled.ul`
    order: 3;
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    flex: 1;
`
const NavItemRight = styled.li`
    margin-left: 2em;
`
const ListLink = props => (
    <Link to={props.to}>{props.children}</Link>
)
export default ({ children }) => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )
    return (
        <LayoutWrapper>
            <HeaderWrapper>
                <BrandWrapper>
                    <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
                        <h3 style={{ display: `inline`, textTransform: `lowercase` }}>{data.site.siteMetadata.title}</h3>
                    </Link>
                </BrandWrapper>
                <BlogNav />
                <SecondaryNavItems>
                    <NavItemRight>
                        <ListLink to="/">About</ListLink>
                    </NavItemRight>
                </SecondaryNavItems>
            </HeaderWrapper>

            {children}
        </LayoutWrapper>
    )
}